import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import log from 'loglevel';
import ReactGA from 'react-ga4';

// CSS
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'react-big-calendar/lib/sass/styles.scss';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';
import './index.css';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { setRegistration, onSuccess, onUpdate } from './comp/DataStore/Sw';
import { DataStoreProvider } from './comp/DataStore';

const level: any = process.env.REACT_APP_LOG_LEVEL || 'warn';
log.setLevel(level);

// ga4 measurement id
const ga4mid = process.env.REACT_APP_GA_MEASUREMENT_ID || '';
ReactGA.initialize(ga4mid);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Router>
    <DataStoreProvider>
      <App />
    </DataStoreProvider>
  </Router>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({ setRegistration, onSuccess, onUpdate });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
